import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { User } from '../../../Authentication/models/auth.models';
import { AuthService } from '../../../Authentication/services/auth/auth.service';
import {
  AddAddress,
  AddEnquiry,
  AddOrder,
  AddOrderResponse,
  AddReview,
  EditAddress,
  GenericResponse,
  ProductListResponse,
  UpdatePaymentStatus,
  filteredProductListRequest,
} from '../../models/models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private auth: AuthService) {}

  getShopMenu() {
    let url = this.apiUrl + 'detail/web-menu';

    return this.http.get<GenericResponse>(url);
  }

  globalSearch(obj: { search_input: string }) {
    let url = this.apiUrl + 'detail/product-global-search';

    return this.http.post<GenericResponse>(url, obj);
  }

  getCategoryList() {
    let url = this.apiUrl + 'detail/main-category-list';

    return this.http.get<GenericResponse>(url);
  }

  getSubcategoryList(slug: string) {
    let url = this.apiUrl + `detail/dependent-subcategory?slug_value=${slug}`;

    return this.http.get<GenericResponse>(url);
  }

  getProductList(filterObj: filteredProductListRequest) {
    let url = this.apiUrl + 'detail/dependent-product-basedon-filters';

    return this.http.post<ProductListResponse>(url, filterObj);
  }

  getProductDetails(productSlug: string) {
    let url = this.apiUrl + `detail/product-detail?slug_value=${productSlug}`;

    return this.http.get<GenericResponse>(url);
  }

  getProductSizes(productId: number, colorId: number) {
    let url =
      this.apiUrl +
      `detail/product-specs?product_Id=${productId}&color_Id=${colorId}`;

    return this.http.get<GenericResponse>(url);
  }

  updateUserDetails(obj: FormData) {
    let url = this.apiUrl + 'customer/update-customer';

    return this.http
      .post<GenericResponse>(url, obj, {
        headers: { token: 'true' },
      })
      .pipe(
        tap((res) => {
          this.updateLoadedUser(res);
        })
      );
  }

  updateLoadedUser(res: GenericResponse) {
    if (res.status !== 'success') return;

    const data = localStorage.getItem('userData');
    if (data) {
      const userData: {
        firstName: string;
        lastName: string;
        phone: string;
        email: string;
        id: string;
        rewardPoints: number;
        _token: string;
        _tokenExpirationDate: string;
      } = JSON.parse(data);

      const loadedUser = new User(
        res.data['first_name'],
        res.data['last_name'],
        res.data['mobile_number'],
        res.data['email'],
        res.data['id'],
        res.data['reward_points'],
        userData._token,
        new Date(userData._tokenExpirationDate)
      );

      this.auth.userSubject.next(loadedUser);
      localStorage.setItem('userData', JSON.stringify(loadedUser));
    }
  }

  getAddressList() {
    let url = this.apiUrl + 'customer/address-list';

    return this.http.get<GenericResponse>(url, {
      headers: { token: 'true' },
    });
  }

  getCountryList() {
    let url = this.apiUrl + 'customer/country-list';

    return this.http.get<GenericResponse>(url);
  }

  getStateList(obj: { country_id: string }) {
    let url = this.apiUrl + 'customer/state-list';

    return this.http.post<GenericResponse>(url, obj);
  }

  getCityList(obj: { state_id: string }) {
    let url = this.apiUrl + 'customer/city-list';

    return this.http.post<GenericResponse>(url, obj);
  }

  addAddress(obj: AddAddress) {
    let url = this.apiUrl + 'customer/add-address';

    return this.http.post<GenericResponse>(url, obj, {
      headers: { token: 'true' },
    });
  }

  deleteAddress(obj: { id: string }) {
    let url = this.apiUrl + 'customer/delete-address';

    return this.http.post<GenericResponse>(url, obj, {
      headers: { token: 'true' },
    });
  }

  editAddress(obj: EditAddress) {
    let url = this.apiUrl + 'customer/update-address';

    return this.http.post<GenericResponse>(url, obj, {
      headers: { token: 'true' },
    });
  }

  addEnquiry(obj: AddEnquiry) {
    let url = this.apiUrl + 'customer/add-enquiry';

    return this.http.post<GenericResponse>(url, obj);
  }

  addReview(obj: AddReview) {
    let url = this.apiUrl + 'customer/add-review';

    return this.http.post<GenericResponse>(url, obj, {
      headers: { token: 'true' },
    });
  }

  createOrder(obj: AddOrder) {
    let url = this.apiUrl + 'checkout/web-add-order';

    return this.http.post<AddOrderResponse>(url, obj, {
      headers: { token: 'true' },
    });
  }

  updatePaymentStatus(obj: UpdatePaymentStatus) {
    let url = this.apiUrl + 'checkout/order-payment-status';

    return this.http.post<GenericResponse>(url, obj, {
      headers: { token: 'true' },
    });
  }

  orderHistory() {
    let url = this.apiUrl + 'checkout/customer-order-list';

    return this.http.get<GenericResponse>(url, {
      headers: { token: 'true' },
    });
  }

  orderDetails(obj: { order_id: string }) {
    let url = this.apiUrl + 'checkout/customer-order-detail';

    return this.http.post<GenericResponse>(url, obj, {
      headers: { token: 'true' },
    });
  }

  homeCategoryBanner(){
    let url = this.apiUrl + 'detail/home-category-banner';
    return this.http.get<GenericResponse>(url);
  }

}
